@use './variables.scss';
@use './responsive.scss';

.form-container {
    width: calc(100% - 100px);
    padding: 40px 50px;
    background-color: #F5F5F5;

    &.compact {
      padding: 20px;
    }

    &.bare {
      padding: 0;
      background-color: transparent;
      margin: 0;
      width: 100%;
    }

    .required {
        color: variables.$primary;
        font-size: 14px/38px;
    }
  
    .input-group {
      display: flex;
      margin-top: 10px;

      &:nth-of-type(1) {
        margin: 0;
      }
  
      .input-label {
        display: inline-block;
        font-size: 14px/38px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: 'Rajdhani', sans-serif;
        position: relative;

        .input-label-text,
        .required {
          pointer-events: none;
        }
      }

      @include responsive.sm {
        display: block;
      }
    
    }

    .input-container {
      width: 100%;
      padding-left: 20px;
      position: relative;

      @include responsive.sm {
        padding: 0;
      }

      &:nth-of-type(1) {
        padding: 0;
      }

      select, 
      .textfield,
      &.phone input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #474747;
        margin-top: 5px;
        padding-left: 8px;
        outline: none;
        color: #595959;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        appearance: none;
        position: relative;
        transition: all ease .2s;
        -o-transition: all ease .2s;
        -moz-transition: all ease .2s;
        -webkit-transition: all ease .2s;
        width: calc(100% - 8px);
        height: 37px;

        &:active,
        &:focus {
          border: 1px variables.$secondary solid;
          box-shadow: 0px 2px 8px rgba(51, 94, 117, 0.3);
        }

        &:disabled {
          pointer-events: none;
          border: 0;
          background-color: #E0E0E0 !important;
          border: 1px #E0E0E0 solid !important;
        }
      }

      select {
        cursor: pointer;

        &:active,
        &:focus {
          border: 1px variables.$secondary solid;
          box-shadow: 0px 2px 8px rgba(51, 94, 117, 0.3);
        }
      }

      .textfield { 
          width: calc(100% - 12px);
          height: 35px;

          &:active,
          &:focus {
            border: 1px variables.$secondary solid;
            box-shadow: 0px 2px 8px rgba(51, 94, 117, 0.3);
          }

          &.disabled {
            pointer-events: none;
            border: 0;
            background-color: #E0E0E0;
            border: 1px #E0E0E0 solid;
          }
      }

      .select-container {
        display: block;
        cursor: pointer;

        svg {opacity: 0;}

        &::after {
          content: "";
          width: 0; 
          height: 0; 
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid variables.$primary;
          position: absolute;
          right: 12px;
          top: 39px;
          pointer-events: none;
        }
      }

    }  

    @include responsive.lg {
      width: calc(100% - 40px);
      padding: 20px;
    }
}
  
.btn-group {
  margin-top: 10px;

  &.inline {
    display: flex;

    button {
      margin-left: 20px;
      width: 30%;

      &:nth-of-type(1) {
        margin-left: 0;
        width: 70%;
      }
    }

    @include responsive.sm {
      display: block;
      
      button {
        width: 100% !important;
        margin: 5px 0;
      }
    }
  }

  &:nth-of-type(1) {
    margin: 0;
  }

  button {
      width: 100%;
      height: 40px;
      outline: none;
      margin-top: 10px;
      cursor: pointer;
      font-family: 'Rajdhani', sans-serif;
      text-transform: uppercase;
      font-size: 16px;
      transition: all ease .2s;
      -o-transition: all ease .2s;
      -moz-transition: all ease .2s;
      -webkit-transition: all ease .2s;
      
      &.solid {
          border: 1px variables.$secondary solid;
          background-color: variables.$secondary;
          color: #fff;

          &:hover {
            background-color: #284858;
          }
      }

      &.outline {
          border: 1px #474747 solid;
          background-color: transparent;
          color: #474747;

          &:hover {
            color: #000;
            border: 1px #000 solid;
            background-color: #e9e9e9;
          }
      }
  }
}

.help {
  display: inline-flex;
  justify-content: center;
  position: absolute;

  &::after {
    content: "?";
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #335E75;
    font-size: 12px;
    color: #fff;
    position: absolute;
    transform: translateX(18px) translateY(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 0;
    right: 0px;
  }

  .tooltip {
    width: 200px;
    position: absolute;
    padding: 20px;
    z-index: 5;
    box-shadow: 0px 0px 8px #00000029;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    text-transform: none;
    transition: all ease .2s;
    -o-transition: all ease .2s;
    -moz-transition: all ease .2s;
    -webkit-transition: all ease .2s;
    margin-left: 22px;
    bottom: 20px;
    transform: scale(.5);

    &::after {
      content: "";
      width: 0; 
      height: 0; 
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      
      transform: translateX(-50%) translateY(8px);
    }
  }

  &:hover .tooltip {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}

.react-select__control {
  border-radius: 0 !important;
  border: 1px solid #474747 !important;
  cursor: pointer !important;

  &.react-select__control--is-focused {
    border: 1px variables.$secondary solid !important;
    box-shadow: 0px 2px 8px rgba(51, 94, 117, 0.3) !important;
  }
}

.react-select__indicator-separator {
  display: none;
}