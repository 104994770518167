@use './variables.scss';
@use './responsive.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Rajdhani:wght@700&display=swap');

// Defaults ------------------------------- >

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: variables.$primary;
  font-weight: bold;
  transition: all ease .2s;
  -o-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -webkit-transition: all ease .2s;
}

h1 {
  width: 600px;
  max-width: 100%;
  font-family: 'Rajdhani', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;

  &.form-header {
    width: 100%;
  }
}
h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
}
p {
  width: 100%;
  font-size: 15px/22px;
  &.text-center {margin: 5px auto 0 auto;}
}
.flex {display: flex;}
.flex-end {justify-content: flex-end;}
.flex-start {justify-content: flex-start;}
.text-center {text-align: center;}
.fullwidth {width: 100%;}
.space-1 {margin-top: 1em;}
.space-2 {margin-top: 2em;}
.space-3 {margin-top: 3em;}
.space-4 {margin-top: 4em;}
.space-5 {margin-top: 5em;}
.col {
  width: 100%; 
  padding-left: 30px;
  overflow: visible;

  &:nth-of-type(1) {
    padding: 0;
  }
}

// Global Components ------------------------------- >

.page-container {
  width: 100%;

  @include responsive.lg {
    padding: 20px;
    width: calc(100% - 40px);
  }
}
.container { 
  width: 900px; 
  max-width: 100%; 
  margin: 0 auto;

  @include responsive.lg {
    width: 100%;
    margin: 0;
  }
}
.container-fluid { 
  width: calc(100% - 200px);
  padding: 0 100px;

  @include responsive.xl {
    width: calc(100% - 80px); 
    padding: 40px;
  }

  @include responsive.sm {
    width: calc(100% - 40px); 
    padding: 20px;
  }

  &.flex {
    @include responsive.xl {
      display: block;
      padding-top: 0;

      .col {
        margin-top: 50px;
        padding: 0;

        &:nth-of-type(1) {
          margin-top: 0;
        }
      }
    }
  }
}
header {
  width: 100%;
  height: 55px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-family: 'Rajdhani', sans-serif;

  @include responsive.md {
    font-size: 20px;
  }

  &.pdf {
    width: calc(100% - 20px);
    justify-content: flex-start;
    padding-left: 20px;
  }
}
footer {
  width: calc(100% - 200px);
  height: 70px;
  background-color: black;
  color: #fff;
  padding: 0 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  font-size: 13px;

  &.pdf {
    width: calc(100% - 40px);
    font-size: 12px;
    padding: 0 20px 10px 20px;

    p {
      max-width: 800px;
    }
  }

  p {
    width: 100%;
    margin: 0px 0 0 0;
    font-size: 13px/19px;

    &:nth-of-type(1) {
      margin: 18px 0 0 0;
    }
  }

  .logo {
    margin-top: 15px;
  }

  @include responsive.xl {
    width: calc(100% - 40px);
    padding: 0 20px;
    font-size: 12px;
  }

  @include responsive.lg {
    height: auto;
    padding-bottom: 15px;
  }
}

.toolbar {
  width: 100%;
  height: 40px;
  background-color: #595959;
  margin-top: 2px;
  position: relative;
  
  .backBtn {
    padding-top: 5px;
    padding-left: 205px;

    @include responsive.xl {
      padding-left: 20px;
    }
    
    a {
      width: 80px;
      display: block;
      cursor: pointer;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      background-color: transparent;
      border: 0;
      text-transform: uppercase;
      font-family: 'Rajdhani', sans-serif;
      cursor: pointer;

      img {
        transform: translateX(-10px);
      }
    }

  }
}
.vic-part {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Rajdhani', sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  background-color: #F89828;

  &.pdf {
    width: calc(100% - 40px);
    text-align: left;
    justify-content: flex-start;
    padding: 0 20px;
    font-size: 18px;
    height: 30px;
  }

  @include responsive.sm {
    font-size: 5vw;
  }
}

.sfa-box {
  padding: 10px;
  height: auto;
  min-height: 204px;
  font-size: 16px;
  background-color: #E0E0E0;

  .title {
    font-weight: bold;
    text-transform: uppercase;
  }

  &.clear {
    height: auto;
    background-color: transparent;
    font-size: 12px;
    padding: 0;
  }

  @include responsive.sm {
    height: auto;
  }
}

.add-opt {
  width: 100%;
  margin-top: 35px;
  
  p {
    width: 100%;
    text-align: center;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
  }

  .add-options {
    width: 100%;
    display: flex;

    a {
      width: 100%;
      display: block;
      border: 1px #474747 solid;
      padding: 20px 0 15px 0;
      margin-left: 20px;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        color: #C4C4C4;
        border: 1px #C4C4C4 solid;

        .label {
          color: #C4C4C4;
        }
        path {
          fill: #C4C4C4;
        }
      }

      &:hover {
        color: #000;
        border: 1px #000 solid;
        background-color: #e9e9e9;
      }

      &:nth-of-type(1) {margin: 0;}
      
      .icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .label {
        width: 100%;
        text-align: center;
        color: #000;
        text-transform: uppercase;
      }
    }

    @include responsive.sm {
      display: block;

      a {
        margin: 0 0 10px 0 !important;
      }
    }
  }
}
