.pdf-layout {
    height: calc(100vh - 40px);
    padding: 20px;

    

    .pdf-content {
        min-height: calc(100vh - 175px);
        position: relative;

        p {
            font-size: 12px;
            position: absolute;
            bottom: 0;
        }
    }
}

.pdf-table {
    width: 80%;

    tr {
        
        th {
            text-align: left;
            font-family: 'Rajdhani', sans-serif;
            text-transform: uppercase;
            padding-top: 20px;
            font-size: 14px;
        }

        td {
            font-size: 12px;
        }
    }
}

