$screen-xs-min: 430px;
$screen-sm-min: 638px;
$screen-md-min: 992px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin xs {
    @media (max-width: #{$screen-xs-min}) {
        @content;
    }
  }

@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
      @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-min}) {
      @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
      @content;
  }
}

@mixin xl {

    @media (max-width: #{$screen-sm-min}) {
        @content;
    }

    @media (max-width: #{$screen-md-min}) {
        @content;
    }

    @media (max-width: #{$screen-lg-min}) {
        @content;
    }

    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}