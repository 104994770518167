@use './responsive.scss';

.modal-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,.6);
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: all ease .2s;
    -o-transition: all ease .2s;
    -moz-transition: all ease .2s;
    -webkit-transition: all ease .2s;

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    @include responsive.lg {
        width: calc(100vw - 40px);
        padding: 20px;
    }

    .modal-form {
        width: 670px;
        padding: 40px 50px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%); 

        @include responsive.lg {
            width: calc(100% - 120px);
        }
    }

    .close-modal {
        width: 25px;
        height: 25px;
        right: 20px;
        top: 20px;
        position: absolute;
        transform: rotate(45deg);
        cursor: pointer;

        &::after {
            content: "";
            width: 25px;
            height: 3px;
            background-color: #000;
            position: absolute;
            transform: translateY(10px);
        }

        &::before {
            content: "";
            width: 25px;
            height: 3px;
            background-color: #000;
            position: absolute;
            transform: rotate(90deg) translateX(10px);
        }
    }

    
}
